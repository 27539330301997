






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ILesson } from '@/types/lesson';
import UserAvatar from '@/components/UserAvatar.vue';

@Component({ components: { UserAvatar } })
export default class SortButton extends Vue {
  @Prop() readonly lessons!: Array<ILesson>

  public selectedLessons: Array<ILesson> = [];
  isUpdating = false;

  @Watch('selectedLessons')
  watchSelectedLessons(val: Array<ILesson>) {
    if ((val[0] !== null) && (typeof val[0] !== 'string')) this.$emit('search', val);
    else this.$emit('search', null);
  }

  remove(lesson: ILesson) {
    const index = this.selectedLessons.findIndex(({ id }) => lesson.id === id);
    if (index >= 0) this.selectedLessons.splice(index, 1);
  }
}
