




































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { IFeedback } from '@/types/feedback';
import Comment from '@/components/comments/Comment.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import SearchLessons from '@/components/SearchLessons.vue';
import { store } from '@/store';
import { ILesson } from '@/types/lesson';
import { IUser } from '@/types/user';
import { AUTH } from '@/services/auth';

@Component({
  components: {
    Comment,
    UserAvatar,
    SearchLessons,
  },
})
export default class FeedbackPage extends Vue {
  public timeout: number | undefined = undefined
  public selectedComments: Array<[]> = [];
  public newStatus = false;

  get lessons(): Array<ILesson> {
    return store.feedback.getters.lessons;
  }

  get lessonsAll(): Array<ILesson> {
    return store.feedback.state.lessonsWithMyComments;
  }

  get selectedLesson() {
    return store.feedback.state.selectedLesson;
  }

  set selectedLesson(val: number) {
    store.feedback.mutations.setSelectedLesson(val);
  }

  get comments(): Array<IFeedback> {
    return store.feedback.getters.commentsBySearch;
  }

  get searchComments() {
    return store.feedback.state.searchComments;
  }

  set searchComments(val: string) {
    store.feedback.mutations.setSearchComments(val);
  }

  get user() {
    return AUTH.getUser();
  }

  @Watch('user')
  async watchUser(val: IUser) {
    if (val) await store.feedback.actions.getMyFeedback();
  }

  async created() {
    if (this.user) await store.feedback.actions.getMyFeedback();
  }

  searchLessons(lessons: Array<ILesson> | null) {
    store.feedback.mutations.setLessonsWithMyCommentsBySearch(lessons);
    this.selectedLesson = 0;
  }

  openLessonPage({ id, section, subsection }: { id: string, section: string, subsection: string }) {
    this.$openPage(`/lesson/${id}/${section}/${subsection}`);
  }
}
